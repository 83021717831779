import { ChevronRightIcon, StarIcon } from "@heroicons/react/solid";

import SCREENSHOT from "./assets/images/app_screenshot.png";
import LOGO from "./assets/images/CII_Dark_Logo.svg";

const stats = [
  { value: "1", label: "Stock selection" },
  { value: "2", label: "Option contract selection" },
  { value: "3", label: "Portfolio allocation" },
  { value: "4", label: "Trade timing" },
];
const logos = [
  {
    name: "Transistor",
    url: "https://tailwindui.com/img/logos/transistor-logo-gray-400.svg",
  },
  {
    name: "Mirage",
    url: "https://tailwindui.com/img/logos/mirage-logo-gray-400.svg",
  },
  {
    name: "Tuple",
    url: "https://tailwindui.com/img/logos/tuple-logo-gray-400.svg",
  },
  {
    name: "Laravel",
    url: "https://tailwindui.com/img/logos/laravel-logo-gray-400.svg",
  },
  {
    name: "StaticKit",
    url: "https://tailwindui.com/img/logos/statickit-logo-gray-400.svg",
  },
  {
    name: "Workcation",
    url: "https://tailwindui.com/img/logos/workcation-logo-gray-400.svg",
  },
];
const footerNavigation = {
  main: [
    {
      name: "About",
      href: "https://app.consistentincomeinvestor.com/quickstart",
    },
    {
      name: "Get Started",
      href: "https://app.consistentincomeinvestor.com/quickstart",
    },
    {
      name: "My Strategy",
      href: "https://app.consistentincomeinvestor.com/strategy",
    },
  ],
  social: [
    {
      name: "Seeking Alpha",
      href: "https://seekingalpha.com/author/consistent-income-investor",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            className="cls-1"
            d="M2.13,6.12c0,2.8,1.41,5.1,3.27,5.05,2.61-.09,3.37-2.27,3.37-5.08S7.26,1,5.4,1.02c-2.3,0-3.27,2.3-3.27,5.1M.13,6.12C.44,.14,5.13,0,5.13,0c3.41,0,4.37,3.65,4.37,3.65,.73-1.09,1.4-2.22,2-3.39h1.11c-2.27,5-2.18,7-2.18,7-.19,2.65,1.31,3.57,1.31,3.57,1.4,.89,1.48-1.71,1.48-1.71,.17-.06,.34-.14,.5-.22,.74,3.62-1.48,3.37-1.48,3.37-1.74,.18-2.48-2.64-2.48-2.64-2.46,3.24-5.29,2.54-5.29,2.54C-.38,11.55,0,6.17,0,6.17"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/ConsistIncome",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};

export default function LandingPageExample() {
  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div>
                <img
                  className="h-11 w-auto"
                  src={LOGO}
                  alt="Consistent Income Investor"
                />
              </div>
              <div className="mt-20">
                <div>
                  <a href="#" className="inline-flex space-x-4">
                    <span className="rounded bg-primary-50 px-2.5 py-1 text-xs font-semibold text-primary-500 tracking-wide uppercase">
                      What's new
                    </span>
                    <span className="inline-flex items-center text-sm font-medium text-primary-500 space-x-1">
                      <span>Just shipped version 0.1.0</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </span>
                  </a>
                </div>
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Simplifying Option Selling
                  </h1>
                  <p className="mt-6 text-xl text-gray-500">
                    In seconds, find the most effective contracts to sell
                    according to historic volatility.
                  </p>
                </div>
                <a
                  href="https://app.consistentincomeinvestor.com/tradefinder"
                  className="mt-12 sm:max-w-lg sm:w-full sm:flex"
                >
                  <div className="mt-4 sm:mt-0">
                    <button className="block w-full rounded-md border border-transparent px-5 py-3 bg-primary-500 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10">
                      Try the demo
                    </button>
                  </div>
                </a>
                {/*<div className="mt-6">
                                    <div className="inline-flex items-center divide-x divide-gray-300">
                                        <div className="flex-shrink-0 flex pr-5">
                                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                        </div>
                                        <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                                            <span className="font-medium text-gray-900">Rated 5 stars</span> by over{' '}
                                            <span className="font-medium text-primary-500">500 beta users</span>
                                        </div>
                                    </div>
                                </div>*/}
              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="hidden sm:block">
                <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                <svg
                  className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={392}
                    fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                  />
                </svg>
              </div>
              <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                <img
                  className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                  src={SCREENSHOT}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-base font-semibold text-primary-600 tracking-wide uppercase">
                Stock Selection
              </h2>
              <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                Simplifying a Complex Strategy
              </p>
            </div>
            <div>
              <p className="mt-5 mx-auto text-xl text-gray-500">
                The main use case for this tool is the "wheel strategy". If you
                are familiar with options, this can sound like a relatively
                simple strategy.
              </p>

              <strong>
                <ul className="mt-5 mx-auto text-xl text-gray-500">
                  <li>
                    1. Sell cash secured puts against a stock until you own it
                  </li>
                  <li>
                    2. Sell covered calls against the same stock until you lose
                    it
                  </li>
                  <li>3. Repeat</li>
                </ul>
              </strong>

              <p className="mt-5 mx-auto text-xl text-gray-500">
                The truth is, it is not that simple. Every time you open a
                position, you must sift through a massive option chain and
                decide between strikes, expiration dates, and calls and puts.
              </p>

              <strong>
                <ul className="mt-5 mx-auto text-xl text-gray-500">
                  <li>- Should I sell options ITM or OTM?</li>
                  <li>- Do I trade weeklies or monthlies?</li>
                  <li>
                    - Should I start with a cash secured put or a buy write?
                  </li>
                </ul>
              </strong>

              <p className="mt-5 mx-auto text-xl text-gray-500">
                These are questions I used to myself which is why I developed
                this tool to answer them for me. Since then, it has saved me a
                ton of time, while also improving my odds of success with each
                trade that I make.
              </p>
            </div>
          </div>
        </div>

        {/* CTA section */}
        {/*        <div className="relative mt-24 sm:mt-32 sm:py-16">
          <div aria-hidden="true" className="hidden sm:block">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
            <svg
              className="absolute top-8 left-1/2 -ml-3"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={392}
                fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
              />
            </svg>
          </div>
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="relative rounded-2xl px-6 py-10 bg-primary-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
              <div
                aria-hidden="true"
                className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 1463 360"
                >
                  <path
                    className="text-primary-400 text-opacity-40"
                    fill="currentColor"
                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                  />
                  <path
                    className="text-primary-600 text-opacity-40"
                    fill="currentColor"
                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                  />
                </svg>
              </div>
              <div className="relative">
                <div className="sm:text-center">
                  <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                    Get notified when we&rsquo;re launching.
                  </h2>
                  <p className="mt-6 mx-auto max-w-2xl text-lg text-primary-100">
                    Sagittis scelerisque nulla cursus in enim consectetur quam.
                    Dictum urna sed consectetur neque tristique pellentesque.
                  </p>
                </div>
                <form
                  action="#"
                  className="mt-12 sm:mx-auto sm:max-w-lg sm:flex"
                >
                  <div className="min-w-0 flex-1">
                    <label htmlFor="cta-email" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="cta-email"
                      type="email"
                      className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-500"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-3">
                    <button
                      type="submit"
                      className="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-500 sm:px-10"
                    >
                      Notify me
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>*/}

        {/* Buttons section*/}
        <div className={"flex max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"}>
          <a
            className={
              "mx-4 text-center justify-center flex w-full rounded-md border border-transparent px-5 py-3 bg-primary-500 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10"
            }
            href="https://app.consistentincomeinvestor.com/quickstart"
          >
            <button>See how it works</button>
          </a>
          <a
            className={
              "mx-4 text-center justify-center flex w-full rounded-md border border-transparent px-5 py-3 bg-primary-500 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10"
            }
            href="https://app.consistentincomeinvestor.com/strategy"
          >
            <button>Learn more about my strategy</button>
          </a>
        </div>
      </main>

      {/* Footer section */}
      <footer className="mt-24 bg-gray-900 sm:mt-12">
        <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            {footerNavigation.main.map((item) => (
              <div key={item.name} className="px-5 py-2">
                <a
                  href={item.href}
                  className="text-base text-gray-400 hover:text-gray-300"
                >
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
          <div className="mt-8 flex justify-center space-x-6">
            {footerNavigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-300 flex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="mr-2">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-center text-base text-gray-400">
            Any of the trades or strategies discussed on this website or on
            other platforms should not be considered financial advice. The
            prices found on the “Option Chains” tool are not intended to be
            accurate stock market quotes. Data may be delayed or inaccurate. The
            returns calculated may contain errors and should not be used as an
            indication to buy or sell any securities. You invest at your own
            risk.
          </p>
        </div>
      </footer>
    </div>
  );
}
